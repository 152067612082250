import React, {Component} from 'react';

export default class Pic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            style: {
                opacity: (props.lifeCycle === 0 ? 0 : 1),
                class: (props.lifeCycle === 2 ? "no-anim" : ""),
                transform: "none",
            }
        };

    };

    componentDidMount() {
        if (this.props.firstTime && this.props.lifeCycle) {
            this.calculateStyleReset(this.props, () => {
                setTimeout(() => {
                    this.lifeCycle(this.props);
                }, 1);
            });
        } else {
            this.lifeCycle(this.props)
        }
    }

    componentWillReceiveProps(nextProps) {
        this.lifeCycle(nextProps)
    }

    lifeCycle(data) {
        if (data.lifeCycle === 0) {
            this.calculateStyle(data, 0);
        } else if (data.lifeCycle === 1) {
            this.calculateStyle(data, 1);
        } else if (data.lifeCycle === 2) {
            this.calculateStyleReset(data);
        }
    }

    calculateStyle(data, opacity) {
        let trans;
        switch (data.order) {
            case 0:
                trans = "scale(" + data.zoom[1] + ") translate(calc(100vw - 100%), calc(100vh - 100%))";
                break;
            case 1:
                trans = "scale(" + data.zoom[1] + ") translate(calc(100vw - 100%), 0)";
                break;
            case 2:
                trans = "scale(" + data.zoom[1] + ") translate(0, 0)";
                break;
            case 3:
                trans = "scale(" + data.zoom[1] + ") translate(0, calc(100vh - 100%))";
                break;
            default:
                console.warn("calculateStyle.data.order: ", data.order);
        }
        this.setState({
            style: {
                transform: trans,
                opacity: opacity,
                transition: 'transition: opacity 1s ease, transform ' + this.props.duration + 's linear',
            },
            class: "",
        });
    }

    calculateStyleReset(data, callBck) {
        let trans;
        switch (data.order) {
            case 0:
                trans = "scale(" + data.zoom[0] + ") translate(0, 0)";
                break;
            case 1:
                trans = "scale(" + data.zoom[0] + ") translate(0, calc(100vh - 100%))";
                break;
            case 2:
                trans = "scale(" + data.zoom[0] + ") translate(calc(100vw - 100%), calc(100vh - 100%))";
                break;
            case 3:
                trans = "scale(" + data.zoom[0] + ") translate(calc(100vw - 100%), 0)";
                break;
            default:
                console.warn("calculateStyleReset.data.order: ", data.order);
        }
        this.setState({
            class: "no-anim",
            style: {
                transform: trans,
                opacity: 1,
                transition: 'opacity 1s ease, transform ' + this.props.duration + 's linear',
            },
        }, callBck);
    }

    render() {
        return (
            <img
                className={this.state.class}
                src={this.props.src}
                style={this.state.style}
                alt={this.props.order + " " + this.props.lifeCycle + " " + this.props.firstTime}
            />
        );
    }
}
