import React, {Component} from 'react';
import Pic from './Pic';

export default class SlideShow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            len: (this.props.imgs.length - 1),
            j: (this.props.initJ % (this.props.imgs.length - 1)),
            pics: [],
            firstTime: true,
            loading: [],
        }
    }

    componentWillMount() {
        if (this.state.j >= this.state.len) this.setState({j: 0});
        this.buildHierarchy(true);

        this.interval = setInterval(() => {
            this.state.j >= this.state.len ? (
                this.setState({j: 0})
            ) : (
                this.setState({j: this.state.j + 1})
            );

            this.buildHierarchy(false);
        }, ((this.props.duration - 1) * 1000));
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    buildHierarchy(skipFirst) {
        let a = [];
        let j = this.state.j;

        a.push(this.props.imgs[j === 0 ? this.state.len : (j - 1)]);
        if (skipFirst) {
            a[0].skip = true;
            setTimeout(() => {
                a[0].skip = false;
            }, this.props.duration * 2000);
        }
        a.push(this.props.imgs[j]);
        this.setState({pics: a});

        // pre-cache
        setTimeout(() => {
            const img = new Image();
            img.src = this.props.imgs[j === this.state.len ? 0 : (j + 1)].url;

            let stateL = this.state.loading;
            let thisL = [true];
            stateL.push(thisL);
            this.setState({loading: stateL});
            img.onload = () => {
                thisL[0] = false;
                this.setState({loading: this.state.loading});
            };

        }, ((this.props.duration - 1) * 100));

        setTimeout(() => {
            a.push(this.props.imgs[j === this.state.len ? 0 : (j + 1)]);
            this.setState({pics: a, firstTime: false});
        }, ((this.props.duration - 2) * 1000));
    };

    render() {
        return (
            <div id="slideShowParent">
                {this.state.pics.map((o, i) => {
                    return o.skip ? null : <Pic order={(this.state.j + i) % 4} lifeCycle={i} src={o.url} key={o.url} zoom={o.zoom} firstTime={this.state.firstTime} duration={this.props.duration} />;
                })}
                {/*
                {this.state.pics.map((o, i) => {
                    return o.skip ? null : <span>{o.url}</span>;
                })}
                {this.state.loading.map((o, i) => {
                    return o[0] ? <span>loading</span> : null;
                })}
                */}
            </div>
        );
    }
}
