import React, {Component} from 'react';
import './style.css';
import SlideShow from "./SlideShow";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // index of first img (modulo imgs count)
            j: 0,
            // total duration including transition of each img
            duration: 10,
            imgs: [
                {
                    url: "./imgs/a_m_f_hreben_z.JPG",
                    zoom: ['1.5', '1'],
                }, {
                    url: "./imgs/b_v_t_velicky_vdp_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/ba_cunovo.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/ba_meander_mlaky_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/beckov_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/bor_niz_holbickovci_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/c_m_f_nove_diery_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/cert_brana_gader_dol1_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/cert_skala_hron_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/ch_v_borove_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/ch_v_oblazy_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/cicmany_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/d_m_k_ostry_kamen_zaruby_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/d_sul_v_got_okno_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/dedinka_v_udoli_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/devin_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/diery1_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/do_p_n_jelka5_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/dobs_kopec_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/dp_rohace_pleso_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/drazovce_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/ds_straz_v_zmija_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/dunaj_hullamcsarda_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/dunaj_rameno_bacianske_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/dunajec_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/f_ch_v_borove3_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/g_ba_cunovo_bobrie_ohryzy_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/g_m_k_pod_vysokou_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/g_p_n_jelka2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/h_sul_v_obrov_brana_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/hory_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/i_dunaj_rameno_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/i_v_t_teryho_ch_plesa_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/k_bor_niz_jurikovci_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/kriz_vz_choc_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/kysuce_lysica_rozsutec_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/l_dunaj_hamuliakovo_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/l_velicka_dol_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/levoca_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/m_f_chata_pod_chlebom_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/m_f_horne_diery_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/m_f_kunerad_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/m_f_pod_v_f_krivanom_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/m_k_baba_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/m_k_klokoc_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/m_k_limbach_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/m_k_peterklin_vapenna_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/m_k_zamok_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/m_sul_v_lietava_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/m_v_t_vel_st_dol_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/n_diery3_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/n_t_chopok_i.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/o_devin2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/o_sul_v_maninska_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/o_v_t_zlomisko_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/orava_babia_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/orava_or_lesna_vz_Bucina_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/orava_or_lesna_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/p_bor_niz_morava_kompa_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/p_ch_v_borove2_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/p_m_f_klak_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/p_m_k_taric_skaly_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/p_n_jelka3_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/p_n_jelka_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/p_sul_v_sulov_kotlina2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/piestany_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/r_dunaj_vdg2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/r_kysuce_lysica_rozsutec2_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/r_m_k_peterklin_vapenna2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/rohace_juranova_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/rohace_nch_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/ruina_kostol_i.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/s_ba_cunovo2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/s_ch_v_oblazy1_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/s_diery2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/s_dunaj_mosonsky_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/s_m_f_vel_rozsutec_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/sl_raj_tomas_vyhl_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/spis_hrad_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/sr_dunaj_vdg_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/straz_v_pruzina_strakose_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/sul_v_budzogan_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/sul_v_lietava_vz Strazov_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/t_cert_brana_gader_dol2_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/t_dunaj_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/t_mlynany_sekvoja.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/terchova_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/teryho_chata_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/trencin_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/u_m_f_dolne_diery_tab_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/u_p_n_jelka4_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/u_rohace_stvrte_pleso_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/u_sul_v_sulov_kotlina1_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/v_f_klak_ostra_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/v_f_lysec_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/v_p_n_jelka6_z.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/v_t_mala_st_dol_liska2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/v_t_mala_stud_dol_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/vinica_jur2_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/vrsatec_i.JPG",
                    zoom: ['1', '1'],
                },

                {
                    url: "./imgs/z_sul_v_slnecne skaly_poluvsie_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/zadiel_ties_i.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/zbojnicky_chodnik_z.JPG",
                    zoom: ['1', '1'],
                }, {
                    url: "./imgs/zc_dunaj_mosonsky2_z.JPG",
                    zoom: ['1', '1'],
                },
                //"./imgs/",
            ],
        };
    }

    render() {
        return (
            <div className="App">
                <SlideShow
                    initJ={this.state.j}
                    imgs={this.state.imgs}
                    duration={this.state.duration}
                />
            </div>
        );
    }
}

export default App;
